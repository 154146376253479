import React from 'react';
import tw from 'twin.macro';
import { graphql } from 'gatsby';
import CategoryPageLayout from '../../components/CategoryPageLayout';
import { Inner, headerInnerStyles } from '../../components';
import FilterProvider from '../../utils/hooks/use-filter';

const TITLE = 'Articles on Doing Digital Differently';
const DESCRIPTION =
  'Here you can find all our blog articles, founder interviews & tech talents portraits.';
const SEO_DESCRIPTION =
  'Are you looking for the best articles to read about Denmark? Click here and learn more about the famous work-life balance and unique startup environment.';

const ArticlesPage = ({ data }) => {
  const articles = data.allDatoCmsArticle.edges.map((article) => article.node);
  return (
    <CategoryPageLayout
      title={TITLE}
      description={DESCRIPTION}
      seoDescription={SEO_DESCRIPTION}
      posts={articles}
      slug="articles"
    />
  );
};

export default ArticlesPage;

export const articlesQuery = graphql`
  {
    allDatoCmsArticle(sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          ribbonCaption
          featuredMedia {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: { crop: "focalpoint", fit: "crop", w: "615", h: "450" }
            )
          }
          contentNode {
            childMarkdownRemark {
              excerpt
            }
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
          sortDate: date(formatString: "YYYYMMDD")
          ecospheres {
            label
          }
        }
      }
    }
  }
`;
